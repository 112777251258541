import { useEffect, useMemo, useState } from "react";

import { api } from "services/api";

export const useCities = () => {
  const [cities, setCities] = useState([]);
  useEffect(() => {
    const get = async () => {
      const { data } = await api(`cities`, { method: "GET" });
      const computedCities = data.map(item => ({
        label: item.name,
        value: item.id
      }));
      setCities(computedCities);
    };

    if (!Boolean(cities.length)) {
      get();
    }
  }, [cities.length]);

  const memoizedCities = useMemo(() => cities, [cities]);

  return memoizedCities;
};

export const useSpecialties = () => {
  const [specialties, setSpecialties] = useState([]);
  useEffect(() => {
    const get = async () => {
      const response = await api(`specialties`, { method: "GET" });

      const computedSpecialties = response.map(item => ({
        label: item.name,
        value: item.id
      }));
      setSpecialties(computedSpecialties);
    };

    get();
  }, []);

  const memoizedSpecialties = useMemo(() => specialties, [specialties]);

  return memoizedSpecialties;
};

export const useTestCategories = () => {
  const [testCategories, setTestCategories] = useState([]);
  useEffect(() => {
    const get = async () => {
      const { data } = await api(`test-categories`, { method: "GET" });

      setTestCategories(data);
    };

    if (!Boolean(testCategories.length)) {
      get();
    }
  }, [testCategories.length]);

  const memoizedTestCategories = useMemo(() => testCategories, [
    testCategories
  ]);

  return memoizedTestCategories;
};

export const useTestList = () => {
  const [testList, setTestList] = useState([]);
  useEffect(() => {
    const get = async () => {
      const { data } = await api(`tests`, { method: "GET" });

      setTestList(data);
    };

    if (!Boolean(testList.length)) {
      get();
    }
  }, [testList.length]);

  const memoizedTestList = useMemo(() => testList, [testList]);

  return memoizedTestList;
};

export const useTestResults = () => {
  const [testResults, setTestResults] = useState([]);
  useEffect(() => {
    const get = async () => {
      const { data } = await api(`test-results`, { method: "GET" });

      setTestResults(data);
    };

    if (!Boolean(testResults.length)) {
      get();
    }
  }, [testResults.length]);

  const memoizedTestResults = useMemo(() => testResults, [testResults]);

  return memoizedTestResults;
};
