import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { avatarSize } from 'services/theme'
import { boxShadow } from 'styled-system'

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  border-radius: 50%;
  background-clip: padding-box;
  overflow: hidden;
  ${avatarSize}
  ${boxShadow}
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Avatar = ({ src, boxShadow, avatarSize, defaultSrc, ...rest }) => (
  <Circle
    boxShadow={boxShadow}
    className="avatar"
    src={src}
    avatarSize={avatarSize}
  >
    <Image src={Boolean(src) ? src : defaultSrc} />
  </Circle>
)

Avatar.propTypes = {
  src: PropTypes.string
}

Avatar.defaultProps = {
  src: '',
  small: 'sm',
  defaultSrc: '/app/images/avatar.svg'
}

export default Avatar
