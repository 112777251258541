import React, { useMemo } from 'react'
import { Grid, Flex, Button, Text, Box } from 'ui'
import styled from 'styled-components'

const CourseName = styled(Text)`
  hyphens: auto;
`

const Root = styled(Grid)`
  .button-wrapper {
    text-align: center;

    a {
      text-decoration: none !important;
    }
  }
`

export const ProfileCourseItem = ({ item, index }) => {
  return (
    <Root
      key={index}
      gridTemplateColumns={{ xs: '1fr  107px', sm: '1fr 160px' }}
      p={{ xs: '12px 8px', sm: '16px' }}
      gridGap="15px"
    >
      <Flex>
        <CourseName lineHeight="1.33" fontSize={{ xs: 0, sm: 1 }}>
          {item.name}
        </CourseName>
      </Flex>

      <Flex className="button-wrapper">
        <Button
          as="a"
          href={`/register-course/${item.tbId}`}
          width="100%"
          buttonTheme="green"
          buttonSize="sm"
          color="white"
          fontSize={{ sm: 0, xs: '11px' }}
          text="Перейти"
        />
      </Flex>
    </Root>
  )
}
