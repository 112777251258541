import React, { useMemo } from 'react'
import { Text, Accordion, Grid, Box } from 'ui'
import styled from 'styled-components'
import { UserContext } from 'services/context'
import { useContext } from 'react'
import SocialIcons from 'profile/SocialIcons'
import { themeGet } from 'styled-system'
import { getFormatPhone } from 'utils'

const Label = styled(Text).attrs({
  fontSize: {
    xs: '10px',
    sm: '14px'
  },
  fontWeight: '500',
  lineHeight: '1.93',
  mb: '10px'
})``

const StyledText = styled(Text).attrs({
  color: themeGet('colors.primaryLight'),
  fontSize: { xs: '12px', sm: '14px' }
})``

const ProfileContacts = props => {
  const { user } = useContext(UserContext)
  const { phone, email, links } = user

  const formattedPhone = useMemo(() => getFormatPhone(phone), [phone])

  return (
    <Box mb={{ xs: '20px', sm: '40px' }}>
      <Accordion
        title="Контактная информация"
        defaultOpen={true}
        color="primary"
      >
        <Grid
          p={{ xs: '16px 10px 12px 10px', sm: '16px' }}
          gridGap="20px"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)'
          }}
          border={`1px solid`}
          borders="primary"
        >
          <Box>
            <Label>Телефон</Label>
            <StyledText>{formattedPhone}</StyledText>
          </Box>
          <Box>
            <Label>Почта</Label>
            <StyledText>{email}</StyledText>
          </Box>
          <Box>
            <Label>Социальные сети</Label>
            <StyledText as="div">
              <SocialIcons links={links} />
            </StyledText>
          </Box>
        </Grid>
      </Accordion>
    </Box>
  )
}

export default ProfileContacts
