import React, { createContext, useState, useMemo, useCallback } from 'react'

export const UserContext = createContext({})

export const UserStore = ({ children }) => {
  const [user, setUser] = useState({
    name: '',
    surname: '',
    image: '',
    city: '0',
    cityName: { value: '0', label: '' },
    birthday: '',
    specialty: [],
    id: '',
    courses: []
  })
  const handleSetUser = useCallback(newData => setUser(newData), [setUser])
  const memoisedUser = useMemo(() => user, [user])
  return (
    <UserContext.Provider
      value={{
        user: memoisedUser,
        setUser: handleSetUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
