import React, { useMemo } from 'react'
import { Flex, Avatar, Box, Text, Location } from 'ui'
import SpecialtiesList from 'profile/SpecialtiesList'
import { UserContext, ResourceContext } from 'services/context'
import { useContext } from 'react'
import { getAgeString, getUserLocation } from 'utils'
import { toSrcUrl } from 'services/images'
import Media from 'react-media'
import styled from 'styled-components'

const FullName = styled(Text)`
  word-break: break-word;
`

const ProfileHeader = props => {
  const { user } = useContext(UserContext)
  const { cities } = useContext(ResourceContext)
  const { name, surname, birthday } = user

  const fullName = useMemo(() => `${name} ${surname ? surname : ''}`, [
    name,
    surname
  ])
  const age = useMemo(() => getAgeString(birthday), [birthday])
  const location = useMemo(() => getUserLocation(user, cities), [cities, user])

  return (
    user && (
      <Box>
        <Flex alignItems="flex-start" p={{ xs: '21px 0 12px 0', sm: '30px 0' }}>
          <Avatar avatarSize="md" src={toSrcUrl(user.img)} />
          <Box pl={{ xs: '10px', sm: '30px' }} flex="1">
            <FullName
              wordBreak="break-word"
              fontSize={{ xs: '18px', sm: 3 }}
              mb={2}
              fontWeight={500}
            >
              {fullName}
              <Media query={{ minWidth: 640 }}>
                <Text
                  as="span"
                  display="inline"
                  fontSize={{ xs: '14px', sm: 2 }}
                >
                  {Boolean(age) && `, ${age}`}
                </Text>
              </Media>
            </FullName>

            <Media query={{ maxWidth: 639 }}>
              <Text mb="10px" fontSize={{ xs: '14px', sm: 2 }}>
                {Boolean(age) && `${age}`}
              </Text>
            </Media>

            {location && <Location location={location && location.label} />}
            <Media query={{ minWidth: 640 }}>
              <SpecialtiesList />
            </Media>
          </Box>
        </Flex>
        <Media query={{ maxWidth: 639 }}>
          <SpecialtiesList />
        </Media>
      </Box>
    )
  )
}

ProfileHeader.defaultProps = {
  user: {
    name: '',
    city: null
  }
}

export default ProfileHeader
