import { createGlobalStyle } from 'styled-components'
import { themeGet, variant } from 'styled-system'

const fontSizes = [12, 14, 16, 24, 32, 48, 64, 96, 128]

const space = [0, 4, 8, 16, 32, 64, 128, 256]

const colors = {
  primary: '#203f93',
  primaryLight: '#5167a4',
  primaryDark: '#214196',
  success: '#19b56b',
  failure: '#c2175b',
  background: '#fbfbfc',
  white: '#ffffff',
  border: '#eceef5',
  blue: '#356895',
  blueBackground: '#e7e9fa',
  opacityText: '#7381a7'
}

const layout = {
  container: '913px'
}

const buttonColors = {
  transparent: {
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    color: colors.primary
  },
  light: {
    borderRadius: '2px',
    fontWeight: 'bold',
    border: 'none',
    background: colors.white
  },
  blue: {
    borderRadius: '2px',
    border: 'none',
    background: colors.primary
  },
  green: {
    borderRadius: '2px',
    border: 'none',
    background: '#17c671'
  },
  darkBlue: {
    borderRadius: '2px',
    fontWeight: '500',
    border: `1px solid ${colors.primaryDark}`,
    background: 'transparent',
    boxShadow: '0 2px 4px 0 rgba(33, 65, 150, 0.47)'
  },
  bannerButton: {
    background: '#17c671',
    color: colors.white,
    fontSize: '12px',
    border: 'none',
    borderRadius: '2px'
  }
}

const avatarSizes = {
  xs: {
    width: '40px',
    height: '40px'
  },
  sm: {
    width: '60px',
    height: '60px'
  },
  md: {
    width: '80px',
    height: '80px'
  },
  lg: {
    width: '140px',
    height: '140px'
  }
}

const breakpoints = ['40em', '52em', '64em', '80em']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

const boxBorders = {
  primary: {
    borderColor: colors.border
  },
  primaryLight: {
    borderColor: colors.primaryLight
  }
}

const buttonSizes = {
  md: {
    padding: '9px 23px',

    '@media(min-width: 40em)': {
      padding: '13px 50px'
    }
  },
  sm: {
    padding: '8px 12px',
    '@media(min-width: 40em)': {
      padding: '8px 17px'
    }
  },
  hasIcon: {
    padding: '8px 12px 8px 5px'
  }
}

export const theme = {
  fontSizes,
  space,
  colors,
  layout,
  buttonColors,
  boxBorders,
  buttonSizes,
  avatarSizes,
  breakpoints
}

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${themeGet('colors.background')};
    font-family: 'Roboto' !important;
    margin: 0;
    padding: 0;
    min-width: 320px;
  }

  button:focus {
    outline: none
  }

  .react-joyride__tooltip {
    border-radius: 0 !important;
  }

  .react-joyride__tooltip * {
    text-align: left;
  }

  .react-joyride__tooltip button {
    font-family: Roboto !important;
  }
`

export const buttonColor = variant({
  prop: 'buttonTheme',
  key: 'buttonColors'
})

export const buttonSize = variant({
  prop: 'buttonSize',
  key: 'buttonSizes'
})

export const avatarSize = variant({
  prop: 'avatarSize',
  key: 'avatarSizes'
})

export const boxBorder = variant({
  prop: 'borders',
  key: 'boxBorders'
})
