import React from 'react'

const facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="18"
      viewBox="0 0 9 18"
    >
      <path
        fill="#7381A7"
        fillRule="evenodd"
        d="M8.616 6.253l-.13 2.673-2.618-.025V18H2.026V8.9l-1.97.052L0 6.253h1.867c0-1.334-.332-4.321 1.18-5.403C5.185-.683 9 .326 9 .326L8.52 3.12s-1.32-.235-1.566-.235c-.367 0-1.086.141-1.086.995v2.374h2.748z"
      />
    </svg>
  )
}

export default facebook
