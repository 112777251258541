import React from 'react'
import { Box, Tooltip, Text, Grid, Flex } from 'ui'
import styled from 'styled-components'

const Root = styled(Flex)`
  @media (max-width: 639px) {
    svg {
      width: 14px;
    }
  }
`
const Value = styled(Text).attrs({
  fontSize: '11px',
  color: 'primaryLight'
})``

const Title = styled(Value).attrs({
  fontWeight: '500',
  mb: '2px'
})``

const TestResults = ({ date, count, score, maxScore }) => {
  return (
    <Root>
      <Tooltip icon="exclaim">
        <Grid gridTemplateColumns="repeat(3, minmax(70px, 1fr))" gridGap="10px">
          <Box>
            <Title>Дата</Title>
            <Value>{date}</Value>
          </Box>
          <Box>
            <Title>Попытка</Title>
            <Value>{count}</Value>
          </Box>
          <Box>
            <Title>Баллы</Title>
            <Value>
              {score} из {maxScore}
            </Value>
          </Box>
        </Grid>
      </Tooltip>
    </Root>
  )
}

export default TestResults
