import React from 'react'
import { Flex, Text, Box } from 'ui'
import styled from 'styled-components'
import { withRouter } from 'react-router'

const Arrow = styled(Box)`
  width: 5px;
  height: 5px;
  border-top: 2px solid #214196;
  border-left: 2px solid #214196;
  transform: rotate(-45deg);
`

const Root = styled(Flex)`
  cursor: pointer;
`

const BackButton = ({ history }) => {
  const goBack = () => {
    history.goBack()
  }
  return (
    <Root onClick={goBack}>
      <Arrow />
      <Text pl="7px">Назад</Text>
    </Root>
  )
}

export default withRouter(BackButton)
