import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import routes from 'routes'
import { maxWidth } from 'styled-system'
import Media from 'react-media'

const Img = styled.img`
  width: 100%;
  cursor: pointer;
  ${maxWidth}
`

const Logo = ({ maxWidth, responsive }) => {
  return (
    // Requires rework
    <Link to={routes.root[0]}>
      {responsive && (
        <Media query={{ minWidth: 640 }}>
          {matches =>
            matches ? (
              <Img maxWidth={maxWidth} src="/app/images/logo-desktop.svg" />
            ) : (
              <Img maxWidth={maxWidth} src="/app/images/logo-mobile.svg" />
            )
          }
        </Media>
      )}
      {!responsive && (
        <Img maxWidth={maxWidth} src="/app/images/logo-desktop.svg" />
      )}
    </Link>
  )
}

Logo.defaultProps = {
  maxWidth: '149px',
  responsive: true
}

export default Logo
