import { isProd, isStage } from 'utils'

export const initGTM = () => {
  const head = document.querySelector('head')
  let script = document.createElement('script')
  if (isProd()) {
    script.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-N448VVW');`
  }

  if (isStage()) {
    script.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=xH2hcfllsSCs7GJZjw6MrA&gtm_preview=env-16&gtm_cookies_win=x'; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-N448VVW');`
  }

  head.appendChild(script)
}
