import React from "react";

const verified = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 14 18"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-2 0h18v18H-2z" />
        <path
          fill="#19B56B"
          fillRule="nonzero"
          d="M7 .75l-6.75 3v4.5c0 4.162 2.88 8.055 6.75 9 3.87-.945 6.75-4.838 6.75-9v-4.5L7 .75zm-1.5 12l-3-3 1.058-1.057L5.5 10.627l4.943-4.942L11.5 6.75l-6 6z"
        />
      </g>
    </svg>
  );
};

export default verified;
