import React from "react";

const mail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      viewBox="0 0 10 8"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#203F93"
          fillRule="nonzero"
          d="M9 0H1C.45 0 .005.45.005 1L0 7c0 .55.45 1 1 1h8c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm0 2L5 4.5 1 2V1l4 2.5L9 1v1z"
        />
        <path d="M-1-2h12v12H-1z" />
      </g>
    </svg>
  );
};

export default mail;
