import React from 'react'

const date = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="10.8"
          height="10.467"
          x=".6"
          y="2.544"
          stroke="#FFF"
          strokeWidth="1.2"
          rx="2"
        />
        <path
          fill="#FFF"
          d="M1 2.917h10v2.917H1zM2.4 7H4v1.556H2.4zM8.4 7H10v1.556H8.4zM5.4 7H7v1.556H5.4zM2 0h1v1.944H2zM9 0h1v1.944H9z"
        />
      </g>
    </svg>
  )
}

export default date
