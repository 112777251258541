import { UserContext } from 'services/context'
import { useContext, useEffect } from 'react'

import Cookies from 'js-cookie'
import { api } from 'services/api'

const useAuth = () => {
  const { user, setUser } = useContext(UserContext)
  const urlParams = new URLSearchParams(window.location.search)
  const queryToken = urlParams.get('token')
  const cookieToken = Cookies.get('token')
  const token = queryToken ? queryToken : cookieToken

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await api('self', { method: 'GET' })
        const { id } = data
        const testResults = await api(`user/${id}`, {
          method: 'GET'
        })
        const { data: courses } = await api(`courses`, {
          method: 'GET'
        })

        const { data: bannersData } = await api(`banners`, {
          method: 'GET'
        })

        const banners = bannersData[0]

        const specialties = testResults.stats && testResults.stats.specialties
        setUser({ specialties, courses, banners, ...data })
      } catch (error) {}
    }

    const uid = user && user.id
    if (!uid && token) {
      Cookies.set('token', token)
      getUser()
    }

    if (queryToken) {
      window.history.replaceState({}, document.title, '/app/')
    }
  }, [queryToken, setUser, token, user])
}

export default useAuth
