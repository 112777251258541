import React from 'react'
import { Logo, Container, Box, Flex } from 'ui'
import styled from 'styled-components'

const Root = styled(Box)`
  border-bottom: solid 1px #ebeef7;
`

const HeaderPassport = props => {
  return (
    <Root padding={{ xs: '15px 13px 15px 11px', sm: '29px 0 24px 0' }}>
      <Container>
        <Flex justifyContent="flex-start">
          <Logo />
        </Flex>
      </Container>
    </Root>
  )
}

export default HeaderPassport
