import React from 'react'

const lock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      viewBox="0 0 16 22"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#403F89"
        transform="translate(1 1)"
      >
        <path d="M12 8h2v12H0V8h2V5c0-2.76 2.24-5 5-5s5 2.24 5 5v3zm-2 0V5c0-1.66-1.34-3-3-3S4 3.34 4 5v3h6z" />
        <circle cx="7" cy="14" r="2" />
      </g>
    </svg>
  )
}

export default lock
