import React from "react";

const okCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#F5A623" />
        <g fill="#FFF">
          <path d="M12.497 4c2.095.006 3.779 1.72 3.767 3.84-.01 2.071-1.72 3.749-3.814 3.741-2.072-.007-3.77-1.724-3.758-3.799A3.798 3.798 0 0 1 12.497 4zm-.005 5.646a1.842 1.842 0 0 0 1.843-1.858 1.846 1.846 0 0 0-1.853-1.85 1.847 1.847 0 0 0-1.856 1.872 1.846 1.846 0 0 0 1.866 1.836z" />
          <path d="M16.758 13.145a4.583 4.583 0 0 1-1.64 1.061c-.586.228-1.227.342-1.864.418.096.105.141.155.201.216.863.866 1.729 1.73 2.59 2.598.293.297.355.663.193 1.007-.177.378-.571.624-.958.598-.246-.017-.436-.14-.607-.31-.652-.656-1.315-1.299-1.952-1.966-.186-.193-.275-.157-.44.012a124.64 124.64 0 0 1-1.99 1.997c-.3.297-.657.35-1.007.18-.37-.18-.607-.558-.588-.937.013-.258.139-.453.316-.63l2.557-2.56c.056-.057.109-.117.192-.205-1.162-.123-2.208-.408-3.104-1.11-.111-.085-.226-.17-.33-.268-.392-.377-.431-.808-.12-1.253.265-.38.713-.483 1.175-.265.09.041.175.096.257.152 1.67 1.148 3.967 1.18 5.643.053.165-.126.343-.231.55-.284.4-.103.772.043.988.395.245.4.24.789-.062 1.1z" />
        </g>
      </g>
    </svg>
  );
};

export default okCircle;
