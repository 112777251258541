import React from 'react'
import { Flex, Icon, Text } from 'ui'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const Wrapper = styled(Flex)`
  svg > path {
    fill: ${p => themeGet(`colors.${p.color}`)} !important;
  }
`

const IconTerm = ({ text, icon, color, fontSize }) => {
  return (
    <Wrapper color={color}>
      <Icon name={icon} />
      <Text fontSize={fontSize} color={color} pl="5px">
        {text}
      </Text>
    </Wrapper>
  )
}

export default IconTerm
