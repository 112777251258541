import React, { useContext, useCallback } from "react";
import { Accordion, Box, Grid, Text } from "ui";
import styled from "styled-components";
import ProfileTestItem from "profile/ProfileTestItem";

import { TestsContext } from "services/context";

const TestList = styled(Box)`
  & > *:nth-child(2n) {
    background-color: #e7e9fa;
  }
`;

const TdTitle = styled(Text).attrs({
  fontSize: { xs: "12px", sm: "14px" },
  fontWeight: "500"
})``;

const ProfileTests = props => {
  const { tests } = useContext(TestsContext);

  const getAccordionSubtitle = useCallback(list => {
    const max = list.length;
    const count = list.filter(item => /^сдано/i.test(item.result && item.result.scaleLevel)).length;

    return `Пройдено ${count} из ${max}`;
  }, []);

  return (
    <Box mb={{ xs: "20px", sm: "40px" }}>
      <Text fontSize={{ xs: "20px", sm: "24px" }} mb={{ xs: "13px", sm: "30px" }} fontWeight="500">
        Тестирование
      </Text>
      {Boolean(tests.length) &&
        tests.map(
          category =>
            category.list && (
              <Box mb="20px" key={category.id} data-tour="test-step">
                <Accordion
                  bg="primary"
                  color="white"
                  defaultOpen={false}
                  title={category.name}
                  subtitle={getAccordionSubtitle(category.list)}
                >
                  <Box bg="#fff" borders="#5167a4" borderBottom="1px solid">
                    <Grid
                      gridTemplateColumns={{
                        xs: "1fr 1fr 107px",
                        sm: "1fr 1fr 160px"
                      }}
                      p={{ xs: "18px 8px", sm: "16px" }}
                      borderBottom={"1px solid"}
                      borders="#5167a4"
                      gridGap="15px"
                    >
                      <TdTitle>Название теста</TdTitle>
                      <TdTitle>Результат</TdTitle>
                      {/*thereIsOnePassed(category.list) && (
                        <TdTitle>Пересдача</TdTitle>
                      )*/}
                    </Grid>
                    <TestList>
                      {category.list.map((item, index) => (
                        <ProfileTestItem key={index} item={item} index={index} />
                      ))}
                    </TestList>
                  </Box>
                </Accordion>
              </Box>
            )
        )}
    </Box>
  );
};

export default ProfileTests;
