import React from "react";
import PropTypes from "prop-types";

export const icons = {
  avatar: require("./icons/avatar").default,
  logout: require("./icons/logout").default,
  edit: require("./icons/edit").default,
  location: require("./icons/location").default,
  close: require("./icons/close").default,
  plus: require("./icons/plus").default,
  date: require("./icons/date").default,
  rub: require("./icons/rub").default,
  reload: require("./icons/reload").default,
  lock: require("./icons/lock").default,
  exclaim: require("./icons/exclaim").default,
  doubleCheckMark: require("./icons/doubleCheckMark").default,
  upload: require("./icons/upload").default,
  selectArrow: require("./icons/selectArrow").default,
  vk: require("./icons/vk").default,
  facebook: require("./icons/facebook").default,
  youdo: require("./icons/youdo").default,
  vkCircle: require("./icons/vkCircle").default,
  fbCircle: require("./icons/fbCircle").default,
  twitterCircle: require("./icons/twitterCircle").default,
  okCircle: require("./icons/okCircle").default,
  electro: require("./icons/electro").default,
  furniture: require("./icons/furniture").default,
  plumbing: require("./icons/plumbing").default,
  verified: require("./icons/verified").default,
  timer: require("./icons/timer").default,
  mail: require("./icons/mail").default,
  phone: require("./icons/phone").default,
  right: require("./icons/right").default,
  closeIcons: require("./icons/closeIcons").default,
  reply: require("./icons/reply").default,
  replyAll: require("./icons/replyAll").default
};

const Icon = ({ name, ...rest }) => {
  const Component = icons[name];
  return name ? <Component {...rest} /> : null;
};

Icon.propTypes = {
  name: PropTypes.string
};

Icon.defaultProps = {
  name: ""
};

export default Icon;
