import React from "react";

const vkCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#5167A4" />
        <path
          fill="#FFF"
          d="M17.457 17h-1.01c-.43.001-.803-.17-1.107-.494-.334-.362-.64-.76-.963-1.136a2.984 2.984 0 0 0-.314-.318c-.258-.22-.516-.175-.639.16-.108.296-.14.626-.204.943-.006.034-.001.071-.001.108-.004.434-.19.677-.575.7-1.01.062-1.99-.065-2.902-.61-.773-.463-1.39-1.117-1.924-1.879-.893-1.274-1.589-2.678-2.226-4.122-.173-.391-.333-.79-.5-1.185l-.032-.082c-.146-.397-.025-.624.356-.661.098-.01.197-.004.295-.006.526-.008 1.055-.013 1.582-.022.37-.007.624.158.768.56a12.828 12.828 0 0 0 1.443 2.881c.109.162.242.314.386.439.188.161.352.104.435-.148a2.68 2.68 0 0 0 .113-.563 8.809 8.809 0 0 0-.038-2.12c-.06-.389-.275-.601-.615-.678-.198-.045-.222-.13-.087-.303.178-.235.417-.341.682-.38a7.216 7.216 0 0 1 2.112-.012c.647.094.73.365.75 1.044.013.449-.023.898-.028 1.347-.005.436-.008.872.01 1.308.005.144.056.298.12.426.087.167.211.193.343.07a3.28 3.28 0 0 0 .456-.513 12.01 12.01 0 0 0 1.416-2.835c.147-.42.252-.495.66-.498.712 0 1.424-.005 2.136-.004.13 0 .263.011.386.051.205.068.31.26.235.482-.12.36-.238.735-.429 1.05-.472.785-.986 1.539-1.48 2.308-.098.152-.19.31-.274.472a.59.59 0 0 0 .058.673c.106.138.22.27.336.395.543.588 1.11 1.15 1.537 1.855.09.143.17.299.229.46.11.304.005.577-.272.69-.155.061-.324.087-.49.112-.123.017-.25.004-.374.006h-.36V17z"
        />
      </g>
    </svg>
  );
};

export default vkCircle;
