import React from 'react'
import styled from 'styled-components'
import { Icon, Flex, Text } from 'ui'
import { buttonColor, buttonSize } from 'services/theme'
import {
  display,
  fontSize,
  fontWeight,
  width,
  maxWidth,
  space
} from 'styled-system'

const Root = styled.button`
  ${buttonColor}
  ${buttonSize}
  ${fontWeight}
  ${display}
  ${fontSize}
  ${width}
  ${maxWidth}
  ${space}
  cursor: pointer;
  display: ${p => (p.icon ? 'flex' : 'block')};
  align-items: center;

`
const Button = ({
  buttonTheme,
  buttonSize,
  text,
  icon,
  fontSize,
  fontWeight,
  color,
  handleClick,
  m,
  type,
  ...rest
}) => {
  return (
    <Root
      m={m}
      fontSize={fontSize}
      buttonTheme={buttonTheme}
      buttonSize={buttonSize}
      icon={icon}
      onClick={handleClick}
      type={type}
      {...rest}
    >
      {Boolean(icon) && (
        <Flex m="0 6px 0">
          <Icon name={icon} />
        </Flex>
      )}
      <Text fontSize={fontSize} fontWeight={fontWeight} color={color}>
        {text}
      </Text>
    </Root>
  )
}

Button.defaultProps = {
  fontWeight: 'normal'
}

export default Button
