import React, { useState } from 'react'
import { Box, Icon, Flex } from 'ui'
import styled from 'styled-components'

const Content = styled(Box)`
  /* left: -4px; */
  top: calc(100% + 6px);
  transform: translateX(-49.5%);

  @media (min-width: 640px) {
    transform: none;
  }
  &:before {
    content: '';
    width: 5px;
    height: 5px;
    border-top: 1px solid #5167a4;
    border-left: 1px solid #5167a4;
    transform: rotate(45deg);
    background-color: #fff;

    top: -2px;
    position: absolute;
    transform: rotate(45deg) translateX(-50%);
    left: 52%;

    @media (min-width: 640px) {
      left: auto;
      top: -4px;
      transform: rotate(45deg);
    }
  }
`

const Tooltip = ({ children, icon }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => setIsOpen(false)

  const onOpen = () => setIsOpen(true)

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box position="relative">
      <Flex onMouseLeave={onClose} onMouseEnter={onOpen} onClick={handleClick}>
        <Icon name={icon} />
      </Flex>

      {isOpen && (
        <Content
          left={{ xs: '0', sm: '-4px' }}
          padding="14px 11px"
          border={`1px solid`}
          borders="primaryLight"
          position="absolute"
          bg="white"
          zIndex="3"
          borderRadius="2px"
        >
          {children}
        </Content>
      )}
    </Box>
  )
}

export default Tooltip
