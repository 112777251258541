import React from "react";

const phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-1-1h12v12H-1z" />
        <path
          fill="#203F93"
          fillRule="nonzero"
          d="M2.31 4.395A7.574 7.574 0 0 0 5.605 7.69l1.1-1.1a.497.497 0 0 1 .51-.12c.56.185 1.165.285 1.785.285.275 0 .5.225.5.5V9c0 .275-.225.5-.5.5A8.5 8.5 0 0 1 .5 1C.5.725.725.5 1 .5h1.75c.275 0 .5.225.5.5 0 .625.1 1.225.285 1.785a.502.502 0 0 1-.125.51l-1.1 1.1z"
        />
      </g>
    </svg>
  );
};

export default phone;
