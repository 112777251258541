import React, { useCallback } from 'react'
import { Field } from 'formik'

import { api } from 'services/api'
import { Text, Flex, Box, Avatar, Button, Input } from 'ui'
import { toSrcUrl } from 'services/images'
import { toast } from 'react-toastify'

const UploadAvatar = ({ src }) => {
  const handleUpload = useCallback(async e => {
    const blob = e.target.files[0]
    const fileSize = blob.size / (1024 * 1024)

    if (fileSize > 10) {
      toast.error('Размер фотографии не должен превышать 10 Мб.')
      return null
    } else {
      const formData = new FormData()
      formData.append('file', blob, blob.name)

      const response = await api('upload', {
        method: 'POST',
        body: formData
      })
      return response
    }
  }, [])
  return (
    <Field name="img">
      {({ field, form }) => (
        <Flex pt={{ xs: '0', sm: '72px' }} flexDirection="column">
          <Text
            fontSize={{ xs: '12px', sm: '14px' }}
            fontWeight={{ xs: '500', sm: 'normal' }}
            textAlign="center"
          >
            Фото профиля
          </Text>
          <Box m={{ xs: '10px 0 15px', sm: '26px 0' }}>
            <Avatar
              boxShadow="0 4px 6px 0 rgba(34, 40, 44, 0.3)"
              avatarSize="lg"
              src={toSrcUrl(field.value)}
            />
          </Box>

          <label>
            <Button
              as="div"
              m="0 auto"
              icon="upload"
              text="Загрузить фото"
              buttonSize="hasIcon"
              buttonTheme="darkBlue"
              fontSize="12px"
              color="primaryDark"
            />
            <Input
              style={{ display: 'none' }}
              type="file"
              onChange={async e => {
                const file = await handleUpload(e)
                file && form.setFieldValue(field.name, file.path)
              }}
            />
          </label>
        </Flex>
      )}
    </Field>
  )
}

export default UploadAvatar
