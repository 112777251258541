import React from 'react'

const vk = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
    >
      <path
        fill="#7381A7"
        fillRule="evenodd"
        d="M16.016 12c-.432 0-.866-.002-1.299 0-.551.002-1.032-.226-1.422-.66-.43-.481-.824-1.01-1.24-1.513a3.914 3.914 0 0 0-.402-.424c-.332-.293-.664-.234-.822.213-.138.394-.18.835-.262 1.258-.008.044-.002.094-.002.143-.004.579-.243.904-.739.934-1.299.083-2.557-.087-3.731-.815-.994-.616-1.787-1.487-2.474-2.504C2.475 6.934 1.58 5.062.76 3.136.539 2.614.332 2.083.118 1.556l-.041-.11C-.11.918.045.616.535.566.66.55.788.56.914.557 1.59.547 2.27.54 2.948.528c.476-.01.802.21.988.746A17.362 17.362 0 0 0 5.79 5.117a3 3 0 0 0 .497.584c.242.215.453.139.56-.197a3.69 3.69 0 0 0 .144-.75c.084-.944.088-1.888-.049-2.827-.076-.518-.353-.802-.79-.905C5.897.963 5.867.85 6.04.618c.23-.313.536-.455.877-.507A8.95 8.95 0 0 1 9.632.096c.833.125.94.487.965 1.392.017.598-.03 1.198-.036 1.796-.007.581-.011 1.163.012 1.744.007.192.073.397.156.568.11.222.27.258.44.094.214-.207.418-.433.586-.685.767-1.157 1.361-2.425 1.82-3.78.19-.56.325-.66.849-.663.915 0 1.83-.008 2.747-.006.166 0 .338.015.496.068.264.09.398.347.302.643-.155.479-.306.98-.551 1.399-.607 1.047-1.268 2.052-1.904 3.078a8.098 8.098 0 0 0-.352.63.81.81 0 0 0 .075.897c.136.183.283.359.432.527.699.784 1.427 1.532 1.977 2.473.114.19.217.398.293.614.143.404.007.769-.349.92-.2.08-.417.116-.63.148-.158.023-.322.006-.481.009h-.463V12z"
      />
    </svg>
  )
}

export default vk
