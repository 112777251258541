import React from 'react'

import Select, { components } from 'react-select'
import styled from 'styled-components'
import { Box, Text, Icon } from 'ui'
import { color, space, themeGet } from 'styled-system'
const StyledSelect = styled(Select)`
  .custom-select__control {
    ${color}
    ${space}
    background-color: transparent;
    border: 1px solid ${p => (p.valid ? '#c4c1d5' : ' #e32918')};
    padding: 0 10px;
    border-radius: 2px;
    font-size: 14px;
    color: ${p => (p.valid ? themeGet('colors.opacityText') : '#e32918')};
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s;
    min-height: 0;
    height: 36px;

    &--is-focused {
      box-shadow: 0 2px 4px 0 rgba(33, 65, 150, 0.47);
      border: solid 1px #214196;
      background-color: #fbfbfc;
      color: #214196;
      .custom-select__single-value {
        color: #214196;
      }
      &:hover {
        border-color: #214196;
      }
    }
  }

  .custom-select__menu {
    border: 1px solid #c4c1d5;
    box-shadow: 0 2px 4px 0 rgba(115, 129, 167, 0.25);
    padding: 0;
    border-radius: 2px;
  }

  .custom-select__single-value {
    color: ${() => themeGet('colors.opacityText')};
  }

  .custom-select__option {
    color: ${() => themeGet('colors.opacityText')};
    text-align: center;
    font-size: 14px;
    &:not(:last-child) {
      border-bottom: 1px solid #c4c1d5;
    }

    &--is-focused,
    &--is-selected {
      background-color: #fff;
    }
    &:hover {
      background-color: #f4f3f6;
    }
  }

  .custom-select__menu-list {
    padding: 0;
  }

  .custom-select__indicator {
    padding-right: 0;
  }

  .custom-select__value-container {
    padding: 0;
  }

  .custom-select__placeholder {
    color: #c4c1d5;
  }

  .custom-select__indicator-separator {
    display: none;
  }
`

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon name="selectArrow" />
      </components.DropdownIndicator>
    )
  )
}

const CustomSelect = ({ label, onBlur, handleChange, ...rest }) => {
  return (
    <Box>
      {Boolean(label) && <Text mb="12px">{label}</Text>}

      <StyledSelect
        {...rest}
        components={{ DropdownIndicator }}
        className="custom-select"
        classNamePrefix="custom-select"
        onChange={handleChange}
      />
    </Box>
  )
}

CustomSelect.defaultProps = {
  placeholder: '',
  isSearchable: false,
  valid: true
}

export default CustomSelect
