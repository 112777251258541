import React from 'react'
import { CustomSelect, Grid, Box, Text } from 'ui'
import { Field } from 'formik'
import { getProps } from 'services/form'

import { useDate } from './hooks'

const DateSelect = ({
  label,
  values,
  setFieldValue,
  id,
  valid,
  onBlur,
  ...rest
}) => {
  const { daysOptions, yearsOptions, monthsOptions } = useDate({
    minAge: 18,
    setFieldValue,
    values
  })
  return (
    <Box>
      <Text mb="12px">Дата рождения</Text>
      <Grid gridTemplateColumns="1fr 1fr 1fr" gridGap="10px">
        <Field name="day">
          {({ field, form }) => {
            return (
              <CustomSelect
                {...getProps(field.name, { ...form, ...field })}
                value={field.value}
                options={daysOptions}
                handleChange={opt => {
                  form.setFieldValue(field.name, opt)
                }}
              />
            )
          }}
        </Field>
        <Field name="month">
          {({ field, form }) => (
            <CustomSelect
              {...getProps(field.name, { ...form, ...field })}
              value={field.value}
              options={monthsOptions}
              handleChange={opt => form.setFieldValue(field.name, opt)}
            />
          )}
        </Field>
        <Field name="year">
          {({ field, form }) => (
            <CustomSelect
              {...getProps(field.name, { ...form, ...field })}
              value={field.value}
              options={yearsOptions}
              handleChange={opt => form.setFieldValue(field.name, opt)}
            />
          )}
        </Field>
      </Grid>
    </Box>
  )
}

export default DateSelect
