import React from "react";

const idSvg = Math.random();

const timer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id={idSvg} fill="none" fillRule="evenodd">
        <path d="M-2 0h18v18H-2z" />
        <path
          fill="#F5A623"
          fillRule="nonzero"
          d="M9.25.75h-4.5v1.5h4.5V.75zm-3 9.75h1.5V6h-1.5v4.5zm6.019-4.961l1.069-1.069a8.345 8.345 0 0 0-1.062-1.061l-1.069 1.069A6.745 6.745 0 0 0 .25 9.75a6.745 6.745 0 0 0 6.746 6.75 6.751 6.751 0 0 0 6.754-6.75 6.706 6.706 0 0 0-1.481-4.211zM7 15A5.251 5.251 0 0 1 7 4.5 5.251 5.251 0 0 1 7 15z"
        />
      </g>
    </svg>
  );
};

export default timer;
