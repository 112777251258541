import styled from 'styled-components'
import {
  space,
  width,
  maxWidth,
  minHeight,
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderWidth,
  alignItems,
  borderStyle,
  borderColor,
  borderRadius,
  display,
  gridTemplateColumns,
  gridTemplateRows,
  gridGap,
  gridColumn,
  gridAutoFlow
} from 'styled-system'
import { boxBorder } from 'services/theme'

const Grid = styled.div`
  ${display}
  ${space}
  ${width}
  ${maxWidth}
  ${minHeight}
  ${border}
  ${alignItems}
  ${borderTop}
  ${borderRight}
  ${borderBottom}
  ${borderLeft}
  ${borderWidth}
  ${borderStyle}
  ${borderColor}
  ${borderRadius}
  ${boxBorder}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${gridGap}
  ${gridColumn}
  ${gridAutoFlow}
`

Grid.defaultProps = {
  gridTemplateColumns: 'repeat(auto-fit, 155px)',
  display: 'grid'
}

export default Grid
