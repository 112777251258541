import styled from 'styled-components'
import {
  space,
  fontSize,
  textAlign,
  lineHeight,
  fontWeight,
  display,
  color,
  maxWidth,
  width
} from 'styled-system'

const Text = styled.p`
  ${space}
  ${fontSize}
  ${textAlign}
  ${lineHeight}
  ${fontWeight}
  ${display}
  ${color}
  ${maxWidth}
  ${width}

`

Text.defaultProps = {
  m: 0,
  color: 'primary',
  fontSize: '14px',
  fontWeight: 'normal'
}

export default Text
