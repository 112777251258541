import { isEmpty } from 'ramda'

export const or = (a, b) => a || b

export const getProps = (id, props) => {
  const { errors, touched, values, handleChange, setFieldValue, onBlur } = props

  return {
    id,
    valid: or(!touched[id], !errors[id]),
    value: values[id],
    onChange: handleChange,
    onBlur: onBlur,
    setFieldValue: setFieldValue
  }
}

export const scrollToInvalid = (isValid, isSubmitting, errors) => {
  if (isValid || isEmpty(errors) || !isSubmitting) return

  const element = document.getElementById(Object.keys(errors)[0])

  element.scrollIntoView({ behavior: 'smooth', block: 'center' })
}
