import Cookies from 'js-cookie'
import { differenceInYears } from 'date-fns'
import formatStringByPattern from 'format-string-by-pattern'

export const PROD_SERVER = 'platform.frontliner.tech'
export const STAGE_SERVER = 'frontliner.lite.network'
export const DEMO_SERVER = 'demo.frontliner.tech'

export const getCookie = name => Cookies.get(name)

export const getQueryParams = name => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

export const isProd = () => window.location.hostname === PROD_SERVER

export const isStage = () => window.location.hostname === STAGE_SERVER

export const isDemo = () => window.location.hostname === DEMO_SERVER

export const getTeachbaseUri = () => {
  if (isProd()) return 'https://frontliner.tech/courses/electricians-form'
  if (isStage()) return '/register-course/129979'
  if (isDemo()) return 'https://frontliner.tech/courses/electricians'

  return 'https://frontliner.tech/courses/electricians-form'
}

export const ageDeclension = (n, forms = ['год', 'года', 'лет']) => {
  n = Math.abs(n) % 100
  var n1 = n % 10
  if (n > 10 && n < 20) {
    return forms[2]
  }
  if (n1 > 1 && n1 < 5) {
    return forms[1]
  }
  if (n1 === 1) {
    return forms[0]
  }
  return forms[2]
}

export const getAgeString = birthday => {
  const ageNum = birthday
    ? differenceInYears(new Date(), new Date(birthday))
    : ''
  const ageString = ageNum ? `${ageNum} ${ageDeclension(ageNum)}` : ''
  return ageString
}

export const getUserLocation = (user, cities) =>
  user && cities.find(item => user.city == item.value)

export const getFormatPhone = phone =>
  formatStringByPattern('+7 (999) 999-99-99', phone)
