import { PublicInfoContext } from 'services/context'
import { useContext, useEffect } from 'react'
import { api } from 'services/api'

const usePublic = id => {
  const { info, setInfo } = useContext(PublicInfoContext)

  useEffect(() => {
    const getInfo = async () => {
      const data = await api(`user/${id}`, {
        method: 'GET'
      })
      setInfo(data)
    }

    if (!info.user.id) {
      getInfo()
    }
  }, [id, info, setInfo])
}

export default usePublic
