import styled from 'styled-components'
import {
  space,
  width,
  maxWidth,
  minHeight,
  color,
  flex,
  position,
  zIndex,
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderWidth,
  borderStyle,
  borderColor,
  borderRadius,
  boxShadow,
  display,
  height,
  left
} from 'styled-system'
import { boxBorder } from 'services/theme'

const Box = styled.div`
  ${space}
  ${width}
  ${maxWidth}
  ${minHeight}
  ${color}
  ${flex}
  ${position}
  ${zIndex}
  ${border}
  ${borderTop}
  ${borderRight}
  ${borderBottom}
  ${borderLeft}
  ${borderWidth}
  ${borderStyle}
  ${borderColor}
  ${borderRadius}
  ${boxBorder}
  ${boxShadow}
  ${display}
  ${height}
  ${left}

`

export default Box
