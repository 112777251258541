import React, { useState, useEffect, useContext } from 'react'
import Joyride from 'react-joyride'

import { Box } from 'ui'
import { UserContext } from 'services/context'

const steps = [
  {
    disableBeacon: true,

    target: '[data-tour="edit-step"]',
    content:
      'Вам необходимо заполнить информацию о себе, для этого нажмите “Редактировать“.'
  },
  {
    target: '[data-tour="test-step"]',
    content: 'Пройдите тест. Оцените свой уровень мастерства.'
  }
]

const Tours = () => {
  const [run, setRun] = useState(false)
  const { user } = useContext(UserContext)

  useEffect(() => {
    const id = user && user.id

    if (id && !localStorage.getItem(id)) {
      setTimeout(() => setRun(true), 1000)

      localStorage.setItem(id, '1')
    }
  }, [user])

  return (
    <Box>
      <Joyride
        run={run}
        steps={steps}
        scrollToFirstStep={true}
        scrollOffset={200}
        continuous={true}
        disableOverlayClose={true}
        floaterProps={{
          disableAnimation: true,
          disableFlip: true
        }}
        locale={{ next: 'Далее', back: 'Назад', last: 'Понятно' }}
        styles={{
          options: {
            arrowColor: '#ffffff',
            borderRadius: 0,
            primaryColor: '#203F93',
            textColor: '#203F93'
          }
        }}
      />
    </Box>
  )
}

export default Tours
