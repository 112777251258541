import React, { useMemo } from "react";
import { Grid, Flex, Button, Text, Box, IconTerm } from "ui";
import styled from "styled-components";
import { themeGet } from "styled-system";
import TestResults from "profile/TestResults";
import { format } from "date-fns";

import { api } from "services/api";

const Circle = styled(Box)`
  border-radius: 50%;
  margin-right: 4px;
  width: 8px;
  height: 8px;
  background-color: ${p => themeGet(`colors.${p.isPassed ? "success" : "failure"}`)};

  @media (min-width: 640px) {
    width: 12px;
    height: 12px;
  }
`;

const StyledBox = styled(Box)`
  @media (min-width: 640px) {
    svg {
      width: 46px;
    }
  }
`;

const TestName = styled(Text)`
  hyphens: auto;
`;

const pluralDays = n => {
  let dec;

  return (
    n +
    " " +
    (((dec = n % 100) >= 11 && dec <= 19) || (dec = n % 10) >= 5 || dec === 0
      ? "дней"
      : dec === 1
      ? "день"
      : "дня")
  );
};

const ProfileTestItem = ({ item, index }) => {
  const { result } = item;

  const goTest = async id => {
    let { data } = await api(`start-test?testId=${id}`, { method: "GET" });

    window.location.href = data.url;
  };

  const dateBetween = useMemo(() => item.timeout, [item]);

  const isSuccess = useMemo(
    () => result && /^сдано/i.test(result.scaleLevel) && result.data.proctoring.conclusion !== 1,
    [result]
  );
  const date = useMemo(() => format(new Date(result && result.createdAt), "DD.MM.YYYY"), [result]);
  return (
    <Grid
      key={index}
      gridTemplateColumns={{ xs: "1fr 1fr 107px", sm: "1fr 1fr 160px" }}
      p={{ xs: "12px 8px", sm: "16px" }}
      gridGap="15px"
    >
      <Flex>
        <TestName lineHeight="1.33" fontSize={{ xs: 0, sm: 1 }}>
          {item.name}
        </TestName>
      </Flex>

      <Flex>
        {Boolean(result) && (
          <Flex>
            <Circle isPassed={isSuccess} />
            <Text fontSize={{ xs: 0, sm: 1 }} mr={{ xs: "0", sm: "10px" }} width="54px">
              {isSuccess ? "Сдан" : "Не сдан"}
            </Text>

            <TestResults
              date={date}
              count={result.count}
              score={result.score}
              maxScore={result.maxScore}
            />
          </Flex>
        )}
      </Flex>
      <Flex>
        {result && dateBetween > 0 ? (
          <StyledBox>
            <IconTerm
              fontSize={{ xs: 0, sm: 1 }}
              text={`Пройти еще раз через ${pluralDays(dateBetween)}`}
              icon="reload"
            />
          </StyledBox>
        ) : (
          <Button
            handleClick={() => goTest(item.id)}
            width="100%"
            buttonTheme="green"
            buttonSize="sm"
            color="white"
            fontSize={{ sm: 0, xs: "11px" }}
            text="Пройти тест"
          />
        )}
      </Flex>
    </Grid>
  );
};

ProfileTestItem.defaultProps = {
  item: {
    result: {
      scaleLevel: "",
      score: "",
      maxScore: "",
      count: "",
      data: {
        proctoring: {
          conclusion: ""
        }
      }
    }
  }
};

export default ProfileTestItem;
