import React, { useMemo, useContext } from 'react'
import { Box, Text, Button, IconTerm, Flex, Grid } from 'ui'
import styled from 'styled-components'
import { UserContext } from 'services/context'
import Media from 'react-media'

const Image = styled.img`
  width: 100%;
`
const Banner = styled.a``

const ProfileBanner = props => {
  const { user } = useContext(UserContext)
  const banners = useMemo(() => user && user.banners, [user])
  return (
    <Banner href={banners && banners.link} target="_blank">
      <Box mb={{ xs: '24px', sm: '40px' }}>
        <Media query={{ minWidth: 551 }}>
          <Image src={banners && banners.origin} />
        </Media>
        <Media query={{ maxWidth: 550 }}>
          <Image src={banners && banners.mobile} />
        </Media>
      </Box>
    </Banner>
  )
}

export default ProfileBanner
