import React from "react";

const closeIcons = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h18v18H-3V-3zm0 0h18v18H-3V-3z" />
        <path
          fill="#5167A4"
          fillRule="nonzero"
          d="M11.53 1.53L10.47.47 6 4.94 1.53.47.47 1.53 4.94 6 .47 10.47l1.06 1.06L6 7.06l4.47 4.47 1.06-1.06L7.06 6z"
        />
      </g>
    </svg>
  );
};

export default closeIcons;
