import React from 'react'

const furniture = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <defs>
        <circle id="a" cx="19.5" cy="19.5" r="19.5" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use href="#a" />
        </mask>
        <use fill="#AA3C85" href="#a" />
        <circle cx="19.5" cy="19.5" r="18.5" stroke="#FFF" strokeWidth="2" />
        <g stroke="#FFF" strokeWidth="2" mask="url(#b)">
          <path d="M14 11h11v7H14zM11 18h17v8H11z" />
          <path
            strokeLinecap="square"
            d="M13 26v3M26 26v3M23 19v3M16 19v3M16 22h7"
          />
        </g>
      </g>
    </svg>
  )
}

export default furniture
