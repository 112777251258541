import React, { useContext, useCallback } from 'react'
import { Accordion, Box, Grid, Text } from 'ui'
import styled from 'styled-components'
import { ProfileCourseItem } from 'profile/ProfileCourseItem'

import { UserContext } from 'services/context'

const TestList = styled(Box)`
  & > *:nth-child(2n) {
    background-color: #e7e9fa;
  }
`

const TdTitle = styled(Text).attrs({
  fontSize: { xs: '12px', sm: '14px' },
  fontWeight: '500'
})``

export const ProfileCourses = props => {
  const {
    user: { courses }
  } = useContext(UserContext)

  // const getAccordionSubtitle = useCallback(list => {
  //   const max = list.length
  //   const count = list.filter(item =>
  //     /^сдано/i.test(item.result && item.result.scaleLevel)
  //   ).length

  //   return `Пройдено ${count} из ${max}`
  // }, [])

  return (
    <Box mb={{ xs: '20px', sm: '40px' }}>
      <Text
        fontSize={{ xs: '20px', sm: '24px' }}
        mb={{ xs: '13px', sm: '30px' }}
        fontWeight="500"
      >
        Обучение
      </Text>
      <Box mb="20px">
        <Accordion
          bg="primary"
          color="white"
          defaultOpen={false}
          title="Курсы"
          // subtitle={getAccordionSubtitle(category.list)}
        >
          <Box bg="#fff" borders="#5167a4" borderBottom="1px solid">
            {Boolean(courses.length) && (
              <div>
                <Grid
                  gridTemplateColumns={{
                    xs: '1fr 1fr 107px',
                    sm: '1fr 1fr 160px'
                  }}
                  p={{ xs: '18px 8px', sm: '16px' }}
                  borderBottom={'1px solid'}
                  borders="#5167a4"
                  gridGap="15px"
                >
                  <TdTitle>Название курса</TdTitle>
                </Grid>
              </div>
            )}

            <TestList>
              {courses.length ? (
                courses.map((item, index) => (
                  <ProfileCourseItem key={index} item={item} index={index} />
                ))
              ) : (
                <Text
                  p="20px 15px"
                  fontSize="15px"
                  fontWeight="bold"
                  textAlign="center"
                >
                  У вас пока нет доступных курсов. Когда работодатель откроет
                  вам доступ к курсам, вы увидите их здесь.
                </Text>
              )}
            </TestList>
          </Box>
        </Accordion>
      </Box>
    </Box>
  )
}
