import React from "react";

const plumbing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <defs>
        <circle id="a" cx="19.5" cy="19.5" r="19.5" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="#1BB0D4" xlinkHref="#a" />
          <circle cx="19.5" cy="19.5" r="18.5" stroke="#FFF" strokeWidth="2" />
        </g>
        <g stroke="#FFF" strokeWidth="2">
          <path d="M9.37 20l2.348 7.352C14.533 29.124 17.29 30 20 30c2.682 0 5.092-.857 7.263-2.588L29.63 20H9.369z" />
          <path
            strokeLinecap="square"
            d="M16 14v-.5a2.5 2.5 0 1 1 5 0V20M14 18v2M26 18v2"
          />
        </g>
      </g>
    </svg>
  );
};

export default plumbing;
