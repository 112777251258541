import React from "react";

const fbCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#4C6BC2" />
        <path
          fill="#FFF"
          d="M15.701 9.864l-.1 2.079-2.037-.02V19h-2.988v-7.077l-1.533.04L9 9.863h1.452c0-1.037-.258-3.36.918-4.202C13.032 4.468 16 5.254 16 5.254l-.374 2.172s-1.026-.183-1.217-.183c-.285 0-.845.11-.845.774v1.847h2.137z"
        />
      </g>
    </svg>
  );
};

export default fbCircle;
