import React, { useContext, useMemo } from 'react'
import { Grid, Box, Text, Range } from 'ui'
import { UserContext } from 'services/context'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
  @media (max-width: 380px) {
    grid-template-columns: repeat(auto-fit, calc(50% - 9px));
  }
`

const SpecialtiesList = props => {
  const { user } = useContext(UserContext)
  const specialties = user && user.specialties

  return (
    <StyledGrid pt={{ xs: '0', sm: '21px' }} alignItems="end" gridGap="17px">
      {specialties &&
        Object.keys(specialties).map((key, index) => {
          return (
            <Box key={index}>
              <Text
                color="primaryLight"
                wordBreak="break-word"
                mb="12px"
                fontSize={{ xs: 1, sm: 2 }}
              >
                {key}
              </Text>

              <Range value={specialties[key]} />
              <Text textAlign="center" color="primaryLight">
                {specialties[key]}%
              </Text>
            </Box>
          )
        })}
    </StyledGrid>
  )
}

export default SpecialtiesList
