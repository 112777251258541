import React, { useContext } from 'react'
import { Box, BaseTemplate, Container, Button, Flex, Icon } from 'ui'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ProfileHeader from 'profile/ProfileHeader'
import ProfileContacts from 'profile/ProfileContacts'
import ProfileBanner from 'profile/ProfileBanner'
import ProfileTests from 'profile/ProfileTests'
import { ProfileCourses } from 'profile/ProfileCourses'
import { UserContext } from 'services/context'
import { useAuth } from 'hooks'

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const ProfilePage = props => {
  const { user } = useContext(UserContext)
  useAuth()
  return (
    <BaseTemplate>
      <Container>
        <Box
          p={{ xs: '0', sm: '15px 20px', md: '30px 40px' }}
          bg={{ xs: 'transparent', sm: '#fff' }}
        >
          <Box
            bg="#fff"
            p={{ xs: '12px 10px', sm: '0' }}
            border={{ xs: `1px solid`, sm: 'none' }}
            borders="primary"
            mb={{ xs: '12px', sm: '0' }}
          >
            <Flex
              justifyContent={'space-between'}
              pb={{ xs: '13px', sm: '15px', md: '30px' }}
              borderBottom={`1px solid`}
              borders="primary"
            >
              <Flex
                style={{ textDecoration: 'none' }}
                to={`/profile/passport/${user && user.id}`}
                as={Link}
                target="_blank"
              >
                <Button
                  text="Цифровой паспорт"
                  buttonTheme="transparent"
                  textDecoration="none"
                  fontSize={{ md: '14px', xs: '11px' }}
                />
                <StyledFlex
                  width="30px"
                  height="30px"
                  bg="#ebeef7"
                  borderRadius="50%"
                  justifyContent="center"
                >
                  <Icon name="replyAll" />
                </StyledFlex>
              </Flex>
              <Button
                fontWeight={{ xs: '500', sm: 'normal' }}
                buttonTheme="transparent"
                to="/profile/edit"
                style={{ textDecoration: 'none' }}
                as={Link}
                text="Редактировать"
                icon="edit"
                data-tour="edit-step"
              />
            </Flex>
            <ProfileHeader />
          </Box>
          <ProfileContacts />
          <ProfileTests />
          <ProfileCourses />
          <ProfileBanner />
        </Box>
      </Container>
    </BaseTemplate>
  )
}

export default ProfilePage
