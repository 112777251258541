import React from "react";

const electro = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FFF"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <circle cx="19.5" cy="19.5" r="19.5" fill="#396DCD" />
        <path d="M18.958 25.855a5.388 5.388 0 0 1-5.388-5.388v-1.883h10.776v1.883a5.388 5.388 0 0 1-5.388 5.388M15.97 18.584V13.95M21.946 18.584V13.95" />
        <path d="M7.24 19.958c0-3.415 1.46-6.489 3.792-8.63a11.674 11.674 0 0 1 7.926-3.088c6.472 0 11.719 5.247 11.719 11.718 0 6.472-5.247 11.719-11.719 11.719v-5.185" />
      </g>
    </svg>
  );
};

export default electro;
