import React from 'react'

const upload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 14"
    >
      <defs>
        <filter
          id="a"
          width="118.3%"
          height="173.3%"
          x="-9.2%"
          y="-23.3%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feComposite
            in="shadowBlurOuter1"
            in2="SourceAlpha"
            operator="out"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.129411765 0 0 0 0 0.254901961 0 0 0 0 0.588235294 0 0 0 0.473307292 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        filter="url(#a)"
        transform="translate(-11 -8)"
      >
        <path
          fill="#214196"
          fillRule="nonzero"
          d="M22.5 13.5h-2v-3h-3v3h-2L19 17l3.5-3.5zm-7 4.5v1h7v-1h-7z"
        />
        <path d="M13 9h12v12H13z" />
      </g>
    </svg>
  )
}

export default upload
