import React from 'react'
import { Flex, Icon, Text } from 'ui'
import styled from 'styled-components'

const Root = styled(Flex)`
  @media (max-width: 639px) {
    svg {
      width: 10px !important;
    }
  }
`

const Location = ({ location }) => (
  <Root>
    <Icon name="location" />
    <Text
      pl={{ xs: '6px', sm: '10px' }}
      color="#5167a4"
      fontWeight={{ xs: 'normal', sm: '500' }}
    >
      {location}
    </Text>
  </Root>
)

export default Location
