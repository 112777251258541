import React from 'react'

const selectArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="15"
      viewBox="0 0 8 15"
    >
      <g fill="#214196" fillRule="nonzero">
        <path d="M8 9H0l4 5.667zM0 6h8L4 0z" />
      </g>
    </svg>
  )
}

export default selectArrow
