import styled from "styled-components";

import {
  space,
  width,
  minWidth,
  borderRadius,
  position,
  height,
  backgroundColor
} from "styled-system";

const Label = styled.label`
${space}
${width}
${minWidth}
${borderRadius}
${position}
${height}
${backgroundColor}
`;

export default Label;
