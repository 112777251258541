import React from 'react'
import { Flex, Container, Box, Logo, Text, Icon } from 'ui'
import styled from 'styled-components'

const Link = styled(Flex)`
  text-decoration: none;
`

const Footer = props => {
  return (
    <Box bg="blueBackground">
      <Container>
        <Flex
          p={{ xs: '20px 14px', sm: '20px 0 18px 0' }}
          justifyContent="space-between"
        >
          <Logo responsive={false} maxWidth="108px" />
          <Link as="a" href="https://frontliner.tech/legal" target="_blank">
            <Icon name="lock" />
            <Text
              fontSize="12px"
              maxWidth="133px"
              pl="8px"
              className="text-common footer__link"
            >
              Политика конфиденциальности
            </Text>
          </Link>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
