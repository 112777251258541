import React from "react";
import { HeaderPassport, Footer, Box, Grid } from "ui";

const PassportTemplate = ({ children }) => {
  return (
    <Grid
      gridTemplateColumns="100%"
      gridTemplateRows="auto 1fr auto"
      minHeight="100vh"
    >
      <HeaderPassport />
      <Box padding={{ xs: "0", sm: "20px 0" }}>{children}</Box>
      <Footer />
    </Grid>
  );
};

export default PassportTemplate;
