import React, { useContext } from "react";
import { PublicInfoContext } from "services/context";
import { Icon, Box } from "ui";
import { FacebookShareButton, VKShareButton } from "react-share";
import { Helmet } from "react-helmet";

const ShareIcons = props => {
  const url = window.location.href;
  const { info } = useContext(PublicInfoContext);
  const { user, stats } = info;
  const spec =
    stats &&
    Object.keys(stats)
      .map(key => {
        return `${key} ${stats[key].total}/100`;
      })
      .join(", ");
  const fullName =
    user && user.surname ? `${user.name} ${user.surname}` : `${user.name}`;
  const title = `${fullName} - ${spec}`;
  return (
    <>
      <Helmet>
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content="Frontliner – система тестирования и обучения мастеров рабочих профессий."
        />
      </Helmet>
      <Box height="24px">
        <VKShareButton url={url} title={title}>
          <Icon name={"vkCircle"} />
        </VKShareButton>
      </Box>
      <Box height="24px">
        <FacebookShareButton url={url}>
          <Icon name={"fbCircle"} />
        </FacebookShareButton>
      </Box>
      {/*<Icon name={"okCircle"} />
      <Icon name={"twitterCircle"} />*/}
    </>
  );
};

export default ShareIcons;
