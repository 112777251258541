import { useCallback, useEffect, useMemo, useState } from 'react'
import { getYear, getDaysInMonth } from 'date-fns'

export const useDate = ({ minAge, setFieldValue, values }) => {
  const [daysOptions, setDaysOptions] = useState([])

  const { day, month, year } = values

  const getDaysCountInMonth = useCallback(() => {
    const currentMonth = month.value ? parseInt(month.value) : 0
    return getDaysInMonth(new Date(year.value, currentMonth))
  }, [month, year])

  const getDaysOptions = useCallback(() => {
    const daysCount = getDaysCountInMonth()
    return [...Array(daysCount).keys()].map(item => ({
      value: item + 1,
      label: item < 9 ? `0${item + 1}` : item + 1
    }))
  }, [getDaysCountInMonth])

  const yearsOptions = useMemo(() => {
    const now = getYear(Date.now())
    const startYear = getYear(new Date('01/01/1920'))
    const endYear = minAge ? now - minAge : now
    const diff = endYear - startYear

    const arr = [...Array(diff + 1).keys()]
    const options = arr
      .map(item => ({
        label: `${startYear + item}`,
        value: `${startYear + item}`
      }))
      .reverse()

    return options
  }, [minAge])

  const monthsOptions = useMemo(() => {
    return [...Array(12).keys()].map(item => {
      const label = item < 9 ? `0${item + 1}` : `${item + 1}`
      return {
        value: item,
        label
      }
    })
  }, [])

  useEffect(() => {
    setDaysOptions(getDaysOptions())
  }, [day, year, month, getDaysOptions])

  useEffect(() => {
    const daysCount = getDaysCountInMonth()
    const currentDay = day.value ? parseInt(day.value) : 0

    if (currentDay > daysCount) {
      setFieldValue('day', {
        value: `${daysCount}`,
        label: `${daysCount}`
      })
    }
  }, [day, month, year, getDaysCountInMonth, setFieldValue])

  return {
    daysOptions,
    yearsOptions,
    monthsOptions
  }
}
