import React from 'react'
import { Header, Footer, Box, Grid } from 'ui'

const BaseTemplate = ({ children }) => {
  return (
    <Grid
      gridTemplateColumns="100%"
      gridTemplateRows="auto 1fr auto"
      minHeight="100vh"
    >
      <Header />
      <Box padding={{ xs: '0', sm: '20px 0' }}>{children}</Box>
      <Footer />
    </Grid>
  )
}

export default BaseTemplate
