import React from 'react'

const youdo = () => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18">
      <defs>
        <path id="a" d="M.287 6.497h20.378V.172H.287z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(.344 10.547)">
          <mask id="b" fill="#fff">
            <use />
          </mask>
          <path
            fill="#7381A7"
            d="M.287.923C3.108 2.308 6.341 3.096 9.78 3.096c4.019 0 7.76-1.076 10.886-2.924v3.94c-3.258 1.527-6.96 2.39-10.885 2.39-3.377 0-6.587-.639-9.493-1.787V.923z"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#7381A7"
          d="M16.817 6.668c-.994 0-1.799-.726-1.799-1.622 0-.896.805-1.622 1.8-1.622.993 0 1.799.726 1.799 1.622 0 .896-.806 1.622-1.8 1.622zm0-6.294c-2.862 0-5.183 2.092-5.183 4.672 0 2.58 2.321 4.672 5.183 4.672C19.68 9.718 22 7.627 22 5.046c0-2.58-2.32-4.672-5.183-4.672zM5.513 6.665c-.015 0-.032-.003-1.41-.004l.015-3.235c1.354-.002 1.376-.005 1.395-.005.994 0 1.8.726 1.8 1.622 0 .895-.806 1.622-1.8 1.622zm0-6.295c-.023 0-.046.004-2.664.004v.013H.327v7.614l.022-.004v1.715c5.118 0 5.14.003 5.164.003 2.863 0 5.184-2.092 5.184-4.672 0-2.58-2.321-4.672-5.184-4.672z"
        />
      </g>
    </svg>
  )
}

export default youdo
