import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, themeGet } from 'styled-system'

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  ${color}

  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner {
    position: relative;
  }

  .spinner div {
    position: absolute;
    animation: spinner 1s linear infinite;
    width: ${p => (p.small ? '80px' : '160px')};
    height: ${p => (p.small ? '80px' : '160px')};
    top: ${p => (p.small ? '10px' : '20px')};
    left: ${p => (p.small ? '10px' : '20px')};
    border-radius: 50%;
    box-shadow: 0 4px 0 0 ${themeGet('colors.primary')};
    transform-origin: ${p => (p.small ? '40px 41px' : '80px 82px')};
  }

  .spinner {
    width: ${p => (p.small ? '100px' : '200px')} !important;
    height: ${p => (p.small ? '100px' : '200px')} !important;
    transform: translate(${p => (p.small ? '-50px -50px' : '-100px, -100px')})
      scale(1) translate(${p => (p.small ? '50px 50px' : '100px, 100px')});
  }
`

const Spinner = ({ loading, children, small }) => {
  if (!loading) return children

  return (
    <Root small={small}>
      <div className="spinner">
        <div />
      </div>
    </Root>
  )
}

Spinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  small: PropTypes.bool
}

Spinner.defaultProps = {
  children: null,
  small: false
}

export default Spinner
