import React from 'react'

const doubleCheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="10"
      viewBox="0 0 16 10"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0-3h16v16H0z" />
        <path
          fill="#214196"
          fillRule="nonzero"
          d="M12 1.667l-.943-.944-4.227 4.23.943.944L12 1.667zm2.83-.944L7.77 7.78 4.99 5l-.943.943L7.77 9.667l8-8-.94-.944zM.277 5.943L4 9.667l.943-.944L1.22 5l-.943.943z"
        />
      </g>
    </svg>
  )
}

export default doubleCheckMark
