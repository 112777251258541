import { getCookie } from 'utils'
import { logout } from 'services/auth'

export const api = async (path, options = {}) => {
  const token = getCookie('token')

  const host = /^localhost/.test(window.location.host) ? 'http://localhost' : ''
  const endpoint = `${host}/api/${path}`
  const defaultHeaders = {
    Authorization: `Bearer ${token}`
  }

  let headers = {}
  if ('headers' in options) {
    headers = {
      ...defaultHeaders,
      ...options.headers
    }
  } else {
    headers = defaultHeaders
  }

  try {
    const isUpload = path === 'upload'
    const computedBody = isUpload ? options.body : JSON.stringify(options.body)

    if (!isUpload) headers['Content-Type'] = 'application/json'

    const res = await fetch(endpoint, {
      headers,
      method: 'GET',
      ...options,
      body: computedBody
    })

    if (res.status === 401) {
      logout()
    }

    if (res) {
      return res.json()
    }
  } catch (error) {
    console.error(error)
  }
}
