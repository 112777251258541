import React from "react";

const right = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M1.5.5L.442 1.558 3.877 5 .443 8.443 1.5 9.5 6 5z"
        />
        <path d="M-6-4h18v18H-6z" />
      </g>
    </svg>
  );
};

export default right;
