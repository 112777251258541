import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Flex, Icon } from 'ui'
import { getSocial } from 'services/social'

const Root = styled(Flex)`
  width: 24px;
  height: 24px;
  background-color: ${() => themeGet('colors.primaryLight')};
  border-radius: 50%;

  svg path {
    fill: #fff;
  }

  svg {
    max-height: 12px;
    max-width: 13px;
  }
`

const Link = styled.a``

const SocialIcon = ({ name }) => (
  <Link target="_blank" href={name}>
    <Root justifyContent="center">
      <Icon name={getSocial(name)} />
    </Root>
  </Link>
)

export default SocialIcon
