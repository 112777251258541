import React from "react";

const reply = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 15"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#5167A4"
          fillRule="nonzero"
          d="M9.667 3.5V.167L15.5 6l-5.833 5.833V8.417C5.5 8.417 2.583 9.75.5 12.667 1.333 8.5 3.833 4.333 9.667 3.5z"
        />
        <path d="M18-4H-2v20h20z" />
      </g>
    </svg>
  );
};

export default reply;
