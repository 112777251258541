import React, { lazy, Suspense } from 'react'

import { Route, Switch } from 'react-router-dom'

import { Spinner } from 'ui'
import routes from 'routes'

import Profile from 'profile/ProfilePage'
// async imports

const EditProfile = lazy(() => import('profileEdit/ProfileEditPage'))
const DigitalPassport = lazy(() =>
  import('profileDigitalPassport/ProfileDigitalPassportPage')
)

const RootRoutes = () => {
  return (
    <Suspense fallback={<Spinner loading={true} />}>
      <Switch>
        <Route path={routes.root} exact component={Profile} />
        <Route path={routes.editProfile} exact component={EditProfile} />
        <Route
          path={routes.digitalPassport}
          exact
          component={DigitalPassport}
        />
      </Switch>
    </Suspense>
  )
}

export default RootRoutes
