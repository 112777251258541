import React from 'react'

const plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="10"
      viewBox="0 0 11 10"
    >
      <path
        fill="#203f93"
        fillRule="nonzero"
        d="M4.53 9.84V5.69H.384V4.31H4.53V.16h1.383V4.31h4.148V5.69H5.914V9.84z"
      />
    </svg>
  )
}

export default plus
