import React, { Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from 'services/theme'
import RootRoutes from 'RootRoutes'

import { HashRouter as Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify'
import { initGTM } from 'services/gtm'

import { ResourceStore, PublicInfoStore } from 'services/context'

const App = () => {
  initGTM()

  return (
    <Router>
      <PublicInfoStore>
        <ResourceStore>
          <ThemeProvider theme={theme}>
            <Fragment>
              <GlobalStyle />
              <ToastContainer />
              <RootRoutes />
            </Fragment>
          </ThemeProvider>
        </ResourceStore>
      </PublicInfoStore>
    </Router>
  )
}

export default App
