import React from 'react'

const close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
    >
      <path
        className="close-icon"
        fill="#203F93"
        fillRule="nonzero"
        d="M8.771 10.028L5 6.258l-3.771 3.77-1.257-1.257L3.742 5l-3.77-3.771L1.229-.028 5 3.742l3.771-3.77 1.257 1.257L6.258 5l3.77 3.771z"
      />
    </svg>
  )
}

export default close
