import React from "react";

const replyAll = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="13"
      viewBox="0 0 20 13"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#5167A4"
          fillRule="nonzero"
          d="M14.167 2.667v-2.5L20 6l-5.833 5.833v-2.5L17.5 6l-3.333-3.333zm-5 .833V.167L15 6l-5.833 5.833V8.417C5 8.417 2.083 9.75 0 12.667.833 8.5 3.333 4.333 9.167 3.5z"
        />
        <path d="M20-4H0v20h20z" />
      </g>
    </svg>
  );
};

export default replyAll;
