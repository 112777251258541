import React from 'react'
import { Flex, Button } from 'ui'
import { logout } from 'services/auth'

const HeaderControl = props => {
  return (
    <Flex>
      <Button
        buttonTheme="transparent"
        text="Выход"
        icon="logout"
        handleClick={logout}
      />
    </Flex>
  )
}

export default HeaderControl
