import React, { createContext, useMemo } from 'react'

import { useCities, useSpecialties } from 'services/resources'

export const ResourceContext = createContext({})

export const ResourceStore = ({ children }) => {
  const cities = useCities()
  const specialties = useSpecialties()

  const memoisedCities = useMemo(() => cities, [cities])
  const memoisedSpecialties = useMemo(() => specialties, [specialties])

  return (
    <ResourceContext.Provider
      value={{
        cities: memoisedCities,
        specialties: memoisedSpecialties
      }}
    >
      {children}
    </ResourceContext.Provider>
  )
}
