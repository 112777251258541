import React, { memo } from 'react'
import { Box, Text, Icon } from 'ui'
import styled from 'styled-components'
import { color, space, themeGet } from 'styled-system'

const StyledInput = styled.input`
  ${color}
  ${space}

  border: 1px solid ${p => (p.valid ? '#c4c1d5' : ' #e32918')};
  padding: 9px 10px;
  border-radius: 2px;
  font-size: 14px;
  color: ${p => (p.valid ? themeGet('colors.opacityText') : '#e32918')};
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s;
  padding-left: ${p => (p.icon ? '30px' : '10px')};

  ::-webkit-input-placeholder {
    color: #c4c1d5;
  }
  ::-moz-placeholder {
    color: #c4c1d5;
  }
  :-ms-input-placeholder {
    color: #c4c1d5;
  }
  :-moz-placeholder {
    color: #c4c1d5;
  }




  &:focus {
    outline: none;
    box-shadow: ${p =>
      p.valid
        ? '0 2px 4px 0 rgba(33, 65, 150, 0.47)'
        : '0 2px 4px 0 rgba(227, 41, 24, 0.41)'};
    border: solid 1px ${p => (p.valid ? '#214196' : '#e32918')};
    background-color: #fbfbfc;
    color: ${p => (p.valid ? '#214196' : '#e32918')};
  }

  :-internal-autofill-selected {
    background-color: transparent;
    color: ${p => (p.valid ? themeGet('colors.opacityText') : '#e32918')};
  }
`

const StyledBox = styled(Box)`
  position: absolute;
  left: 10px;
  display: flex;
  top: 50%;
  transform: translateY(-50%);

  svg {
    max-width: 16px;
  }
`

const Input = ({ icon, label, maxlength, ...rest }) => {
  return (
    <Box>
      {Boolean(label) && <Text mb={{ xs: '7px', sm: '12px' }}>{label}</Text>}
      <Box position="relative">
        {Boolean(icon) && (
          <StyledBox>
            <Icon name={icon} />
          </StyledBox>
        )}
        <StyledInput maxLength={maxlength} icon={icon} {...rest} />
      </Box>
    </Box>
  )
}

Input.defaultProps = {
  valid: true,
  maxlength: ''
}

export default memo(Input)
