import React from 'react'
import { Box } from 'ui'
import styled from 'styled-components'

const Root = styled(Box)`
  border-radius: 5px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  background-color: #ebeef7;
  height: 4px;
  position: relative;
`

const Inner = styled(Box)`
  position: relative;
  left: 0;
  height: 4px;
  border-radius: 5px;
  background-color: #5167a4;

  &:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #ffffff;
    background-color: #5167a4;
    border-radius: 50%;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    position: absolute;
  }
`

const Range = ({ value }) => {
  return (
    <Root mb="4px">
      <Inner width={`${value}%`} />
    </Root>
  )
}

export default Range
