import React, { createContext, useMemo } from 'react'
import { groupBy, prop, pathOr } from 'ramda'

import {
  useTestCategories,
  useTestList,
  useTestResults
} from 'services/resources'

const getItem = pathOr(null, ['0'])
const groupCategoryId = groupBy(prop('categoryId'))
const groupByTestId = groupBy(prop('testId'))
export const TestsContext = createContext({})

export const TestsStore = ({ children }) => {
  const testCategories = useTestCategories()
  const testList = useTestList()
  const testResults = useTestResults()

  const objTestList = groupCategoryId(testList)
  const objTestResults = groupByTestId(testResults)

  const computedTestList = useMemo(
    () =>
      testCategories.map(item => {
        const itemsList = objTestList[item.id]
        const ff = itemsList
          ? itemsList.map(listItem => ({
              ...listItem,
              result: getItem(objTestResults[listItem.id])
            }))
          : null

        return {
          ...item,
          list: ff
        }
      }),
    [objTestList, objTestResults, testCategories]
  )

  return (
    <TestsContext.Provider
      value={{
        tests: computedTestList
      }}
    >
      {children}
    </TestsContext.Provider>
  )
}
