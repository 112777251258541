import React from 'react'

const logout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h12v12H0z" />
        <path
          fill="#214196"
          fillRule="nonzero"
          d="M5.045 7.795l.705.705L8.25 6l-2.5-2.5-.705.705L6.335 5.5H1.5v1h4.835l-1.29 1.295zM9.5 1.5h-7a1 1 0 0 0-1 1v2h1v-2h7v7h-7v-2h-1v2a1 1 0 0 0 1 1h7c.55 0 1-.45 1-1v-7c0-.55-.45-1-1-1z"
        />
      </g>
    </svg>
  )
}

export default logout
