import React, { createContext, useState, useMemo, useCallback } from 'react'

export const PublicInfoContext = createContext({})

export const PublicInfoStore = ({ children }) => {
  const [info, setInfo] = useState({
    user: {
      name: '',
      surname: '',
      image: '',
      city: '0',
      cityName: { value: '0', label: '' },
      birthday: '',
      specialty: [],
      id: '',
      phone: ''
    }
  })
  const handleSetInfo = useCallback(newData => setInfo(newData), [setInfo])
  const memoisedInfo = useMemo(() => info, [info])
  return (
    <PublicInfoContext.Provider
      value={{
        info: memoisedInfo,
        setInfo: handleSetInfo
      }}
    >
      {children}
    </PublicInfoContext.Provider>
  )
}
