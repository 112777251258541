import React from 'react'
import ProfilePage from './ProfilePage'
import { UserStore, TestsStore } from 'services/context'
import { Tours } from 'ui'

const ConnectedProfilePage = ({ ...rest }) => {
  return (
    <TestsStore>
      <UserStore>
        <Tours />
        <ProfilePage {...rest} />
      </UserStore>
    </TestsStore>
  )
}

export default ConnectedProfilePage
