import React from "react";

const twitterCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="12" cy="12" r="12" fill="#4A90E2" />
        <path
          fill="#FFF"
          d="M17.534 8.18c-.25.227-.68.456-.68.456s.25 1.526-.429 3.281c-.68 1.757-1.505 2.9-3.619 4.12-2.113 1.223-3.51 1.012-4.478.802-.966-.21-1.576-.496-2.328-1.106 0 0 1.265-.078 1.826-.306.7-.282 1.613-.954 1.613-.954s-.572-.115-1.29-.571c-.716-.458-.966-1.222-.966-1.222l1.11-.114s-.753-.267-1.468-1.03c-.718-.763-.574-1.64-.574-1.64s.251.151.5.23c.252.075.647 0 .647 0s-.932-.574-1.076-1.756c-.142-1.183.288-1.794.288-1.794s1.122 1.31 2.22 1.946c1.253.726 3.045.839 3.045.839s-.158-2.658 1.827-3.243c1.684-.495 2.472.726 2.472.726s.322 0 .68-.115a6.68 6.68 0 0 0 .86-.382s0 .19-.107.42c-.108.229-.323.459-.43.572-.109.115-.466.343-.466.343s.25.078.537.039c.286-.039.752-.152.752-.152s-.215.38-.466.61z"
        />
      </g>
    </svg>
  );
};

export default twitterCircle;
