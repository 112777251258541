import React from 'react'
import { Logo, Container, Box, HeaderControl, Flex } from 'ui'
import styled from 'styled-components'

const Root = styled(Box)``

const Header = props => {
  return (
    <Root padding={{ xs: '15px 13px 15px 11px', sm: '29px 0 24px 0' }}>
      <Container>
        <Flex justifyContent="space-between">
          <Logo />
          <HeaderControl />
        </Flex>
      </Container>
    </Root>
  )
}

export default Header
