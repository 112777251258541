import styled from "styled-components";
import {
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flexBasis,
  display,
  space,
  width,
  maxWidth,
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  borderWidth,
  borderStyle,
  borderColor,
  borderRadius,
  background,
  color,
  position,
  height
} from "styled-system";
import { boxBorder } from "services/theme";

const Flex = styled.div`
  ${alignItems}
  ${justifyContent}
  ${flexWrap}
  ${flexDirection}
  ${flexBasis}
  ${display}
  ${space}
  ${width}
  ${maxWidth}
  ${border}
  ${borderTop}
  ${borderRight}
  ${borderBottom}
  ${borderLeft}
  ${borderWidth}
  ${borderStyle}
  ${borderColor}
  ${borderRadius}
  ${boxBorder}
  ${background}
  ${color}
  ${position}
  ${height}
`;

Flex.defaultProps = {
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "no-wrap",
  flexDirection: "row",
  display: "flex"
};

export default Flex;
