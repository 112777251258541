import React from 'react'

const reload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#214196"
          fillRule="nonzero"
          d="M13.8 4.667v-3.5L9.133 5.833 13.8 10.5V7c3.862 0 7 3.138 7 7a6.849 6.849 0 0 1-.817 3.267l1.704 1.703A9.253 9.253 0 0 0 23.133 14 9.33 9.33 0 0 0 13.8 4.667zM13.8 21c-3.862 0-7-3.138-7-7 0-1.178.292-2.298.817-3.267L5.913 9.03A9.253 9.253 0 0 0 4.467 14a9.33 9.33 0 0 0 9.333 9.333v3.5l4.667-4.666L13.8 17.5V21z"
        />
        <path d="M0 0h28v28H0z" />
      </g>
    </svg>
  )
}

export default reload
