import React from 'react'

const rub = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <g fill="none" fillRule="evenodd">
        <rect
          width="12.8"
          height="12.8"
          x=".6"
          y=".6"
          stroke="#FFF"
          strokeWidth="1.2"
          rx="2"
        />
        <path fill="#FFF" d="M5 3h1v8H5z" />
        <path stroke="#FFF" d="M5.505 3.5v4h2.367a2 2 0 1 0 0-4H5.505z" />
        <path fill="#FFF" d="M4 9h4v1H4z" />
      </g>
    </svg>
  )
}

export default rub
