import styled from 'styled-components'
import { space, width, maxWidth, margin } from 'styled-system'

const Container = styled.div`
  ${space}
  ${margin}
  ${width}
  ${maxWidth}
  padding: 0 8px;

  @media(min-width: 640px) {
    padding: 0 15px;
  }
`

Container.defaultProps = {
  width: '100%',
  m: '0 auto',

  maxWidth: '963px'
}

export default Container
