import React, { useState, useCallback } from 'react'
import { Box, Flex, Text, Icon } from 'ui'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const IconWrapper = styled.div`
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: 639px) {
    right: 15px;
  }

  svg > path {
    fill: ${p => themeGet(`colors.${p.color}`)} !important;
  }
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const Accordion = ({ color, bg, children, title, subtitle, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const toggle = useCallback(() => setIsOpen(state => !state), [setIsOpen])

  return (
    <Box>
      <StyledFlex
        position="relative"
        p={{ xs: '11px 48px 11px 10px', sm: '16px 48px 16px 16px' }}
        bg={bg}
        justifyContent="space-between"
        onClick={toggle}
      >
        <Text
          fontSize={{ xs: 0, sm: 2 }}
          pr={{ xs: '40px', sm: '0' }}
          color={color}
          fontWeight="500"
          lineHeight="1.5"
        >
          {title}
        </Text>
        {subtitle && (
          <Text
            maxWidth={{ xs: '60px', sm: '100%' }}
            fontSize={{ xs: 0, sm: 2 }}
            lineHeight="1.5"
            fontWeight="500"
            color={color}
          >
            {subtitle}
          </Text>
        )}
        <IconWrapper color={color}>
          <Icon name={isOpen ? 'close' : 'plus'} />
        </IconWrapper>
      </StyledFlex>
      {isOpen && children}
    </Box>
  )
}

Accordion.defaultProps = {
  bg: '#e7e9fa',
  color: 'primary',
  defaultOpen: false
}

export default Accordion
