import React from 'react'
import { Grid, SocialIcon } from 'ui'
import styled from 'styled-components'

const Root = styled(Grid)`
  margin-top: -5px;
`

const SocialIcons = ({ links }) => (
  <Root gridTemplateColumns="repeat(3, 24px)" gridGap="16px">
    {links.length
      ? links.map((link, index) => <SocialIcon key={index} name={link} />)
      : '-'}
  </Root>
)

SocialIcons.defaultProps = {
  links: []
}

export default SocialIcons
